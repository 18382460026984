/* Custom Stylesheet */


/**
 * Use this file to override Materialize files so you can update
 * the core Materialize files in the future
 *
 * Made By MaterializeCSS.com
 */

* {
    border: 0px;
    padding: 0px;
    margin: 0px;
}

img {
    display: block;
    margin: 0 auto;
}

header,
main,
footer {
    padding-left: 260px;
}

@media only screen and (max-width: 992px) {
    header,
    main,
    footer {
        padding-left: 0;
    }
}

@media only screen and (min-width: 992px) {
    nav,
    nav .nav-wrapper i,
    nav a.button-collapse,
    nav a.button-collapse i {
        height: 0px;
        line-height: 0px;
    }
}

.side-nav li>a {
    font-family: 'gilroylight';
}

.side-nav li>span {
    font-family: 'gilroylight';
    letter-spacing: 0.06rem;
    font-size: 0.9rem;
}

.redes {
    margin-top: 35px;
}

.redes>a {
    display: inline-block !important;
    margin: 0 !important;
}

.col-facebook {
    background-color: #3b5998 !important;
}

.col-instagram {
    background-color: #125688 !important;
}

.col-google {
    background-color: #dd4b39 !important;
}

.col-linkedin {
    background-color: #007bb5 !important;
}

.icon-block {
    padding: 0 15px;
}

.icon-block .material-icons {
    font-size: inherit;
}

.side-nav {
    width: 260px;
    background: rgba(16, 83, 167, 1);
    background: -moz-linear-gradient(top, rgba(16, 83, 167, 1) 0%, rgba(16, 83, 167, 1) 41%, rgba(45, 126, 192, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(16, 83, 167, 1)), color-stop(41%, rgba(16, 83, 167, 1)), color-stop(100%, rgba(45, 126, 192, 1)));
    background: -webkit-linear-gradient(top, rgba(16, 83, 167, 1) 0%, rgba(16, 83, 167, 1) 41%, rgba(45, 126, 192, 1) 100%);
    background: -o-linear-gradient(top, rgba(16, 83, 167, 1) 0%, rgba(16, 83, 167, 1) 41%, rgba(45, 126, 192, 1) 100%);
    background: -ms-linear-gradient(top, rgba(16, 83, 167, 1) 0%, rgba(16, 83, 167, 1) 41%, rgba(45, 126, 192, 1) 100%);
    background: linear-gradient(to bottom, rgba(16, 83, 167, 1) 0%, rgba(16, 83, 167, 1) 41%, rgba(45, 126, 192, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#1053a7', endColorstr='#2d7ec0', GradientType=0);
}

.logo {
    margin-top: 40px;
    margin-bottom: 30px;
}

@media only screen and (max-width: 992px) {
    .logo {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.gral-title {
    font-family: 'hallo_sansregular';
    font-size: 2.6em;
    position: relative;
    display: inline-block;
    margin-top: 40px;
    margin-bottom: 60px;
}

.gral-title:after {
    border-bottom: 4px solid #ff0002;
    content: "";
    display: block;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}


/* -----------------------------------------------------------------------------------------------
SLIDE
-------------------------------------------------------------------------------------------------- */

.slide1 {
    background-image: url('http://lorempixel.com/800/400/food/4');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.bandera {
    position: absolute;
    z-index: 10;
    bottom: 0;
    right: 0;
}

.bandera img {
    margin-right: 0;
}

@media only screen and (max-width: 1200px) {
    .bandera img {
        margin-right: 0;
        max-width: 20vw;
    }
}

.slider .slides li .caption h3 {
    font-family: 'hallo_sansregular';
    font-size: 5em;
    margin-bottom: 0;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.41);
}

.slider .slides li .caption h5 {
    font-family: 'hallo_sansregular';
    font-size: 2.6em;
    margin-top: 0;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.41);
}

@media only screen and (max-width: 992px) {
    .slider .slides li .caption h3 {
        font-size: 3em;
    }
    .slider .slides li .caption h5 {
        font-size: 1.6em;
    }
}

.wrap-video {
    height: 100vh;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .wrap-video {
        height: calc(100vh - 112px);
    }
}

.container-iframe {
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/* -----------------------------------------------------------------------------------------------
NOVEDADES
-------------------------------------------------------------------------------------------------- */

#novedades {
    margin-top: 40px;
}

.wrap-grid {
    padding: 0 !important;
}

.novedad .card-title {
    font-family: 'hallo_sansregular';
    font-size: 2em;
    margin-top: 0;
}

.novedad p {
    font-family: 'gilroylight';
    font-size: 1.2em !important;
}

.card-panel,
.card,
.toast,
.btn,
.btn-large,
.btn-floating,
.dropdown-content,
.collapsible,
.side-nav {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}


/* -----------------------------------------------------------------------------------------------
AMBIENTES
-------------------------------------------------------------------------------------------------- */

#ambientes {
    margin-top: 40px;
}


/* -----------------------------------------------------------------------------------------------
BUSCADOR
-------------------------------------------------------------------------------------------------- */

#buscador {
    border-bottom: 3px solid #ff0002;
}

.buscador-home {
    margin-top: 40px;
}

.wrap-buscador {
    padding-top: 20px;
    margin-bottom: 0;
}

.show-sr {
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: perspective(1000px) translate3d(0, 0, 0);
    transform: perspective(1000px) translate3d(0, 0, 0);
}

.form-busc input[type=text] {
    max-width: 70%;
    height: 3.2rem;
    border-bottom: 1px solid #fff;
    color: #fff !important;
    font-size: 1.4rem;
    font-family: 'gilroylight';
}

.form-busc label {
    color: #fff;
    font-size: 1.3rem;
    font-style: italic;
    letter-spacing: 0.04em;
}

@media only screen and (max-width: 767px) {
    .form-busc input[type=text] {
        max-width: 100%;
        text-align: left !important;
    }
    .form-busc label {
        text-align: left !important;
    }
    .form-busc {
        margin-bottom: 14px;
        text-align: center;
    }
}


/* label color */

.form-busc label {
    color: #fff;
}


/* label focus color */

.form-busc input[type=text]:focus+label {
    color: #bdbdbd;
}


/* label underline focus color */

.form-busc input[type=text]:focus {
    border-bottom: 1px solid #bdbdbd;
    box-shadow: 0 1px 0 0 #bdbdbd;
}


/* valid color */

.form-busc input[type=text].valid {
    border-bottom: 1px solid #9e9e9e;
    box-shadow: 0 1px 0 0 #9e9e9e;
}


/* invalid color */

.form-busc input[type=text].invalid {
    border-bottom: 1px solid #bdbdbd;
    box-shadow: 0 1px 0 0 #bdbdbd;
}


/* icon prefix focus color */

.form-busc .prefix.active {
    color: #bdbdbd;
}

.tit-filt {
    margin-top: 0 !important;
}

.tit-filt .collection-item {
    font-size: 2rem;
    font-family: 'hallo_sanslight';
    line-height: normal !important;
}

.wrap-dropdown {
    margin-top: 25px;
}

.wrap-dropdown .row {
    margin-bottom: 14px !important;
}

.dropdown-button {
    width: 100%;
    font-size: 2rem;
    font-family: 'hallo_sanslight';
    padding: 5px 20px;
    margin: 0;
    display: block;
    text-align: center;
    cursor: pointer;
}

@media only screen and (max-width: 1025px) {
    .dropdown-button {
        font-size: 1.6rem;
    }
}


/* -----------------------------------------------------------------------------------------------
CONTACTO
-------------------------------------------------------------------------------------------------- */

#map_canvas {
    height: 840px;
}

#contacto {
    margin-bottom: 20px;
}

#contacto .row,
#distribuidores .row {
    margin-bottom: 0;
}

#distribuidores {
    padding: 50px 0;
}

.tit-contacto {
    margin-top: 0 !important;
    margin-bottom: 20px;
}

.tit-contacto .collection-item {
    font-size: 1.4rem;
}

.info-contacto i {
    font-size: 1.6em;
}

.info-contacto p {
    font-size: 1.1em;
}

.info-contacto {
    border-bottom: 1px solid #bfd2e9;
    padding-bottom: 40px;
    margin-bottom: 40px;
}

.contactForm button,
.distribuidorForm button {
    width: 100%;
}

.contactForm .input-field {
    margin-top: 0;
}

.warning {
    font-size: 1.2em;
    line-height: normal;
    color: #D50000;
    text-decoration: none;
    padding-top: 15px;
    display: block;
}

.success {
    font-size: 1.2em;
    line-height: 25px;
    color: #D50000;
    text-decoration: none;
}

.crm-webform-header-container {
    display: none !important;
}

.wrap-newsletter {
    border-bottom: 1px solid #bfd2e9;
    padding-bottom: 40px;
    margin-bottom: 40px;
}


/* -----------------------------------------------------------------------------------------------
EMPRESA
-------------------------------------------------------------------------------------------------- */

#empresa {
    margin-bottom: 40px;
    margin-top: 40px;
}

#empresa .row {
    margin-bottom: 0;
}

.text-empresa {
    padding: 23px !important;
    background-color: rgba(13, 71, 161, 0.7)
}

.text-empresa p {
    font-weight: 300;
}

.text-empresa h5 {
    font-weight: 600;
    margin-bottom: 6px !important;
    margin-top: 10px !important;
    padding: 0;
    font-size: 1.4em;
}

.nav-breadcrumb {
    height: 50px;
    line-height: 50px;
    padding-top: 20px;
    font-family: 'hallo_sansregular';
    background-color: transparent;
}

@media only screen and (max-width: 992px) {
    .nav-breadcrumb {
        height: 30px;
        line-height: 30px;
    }
}


/* -----------------------------------------------------------------------------------------------
LINEAS
-------------------------------------------------------------------------------------------------- */

#lineas {
    margin-bottom: 180px;
}


/* -----------------------------------------------------------------------------------------------
FICHA
-------------------------------------------------------------------------------------------------- */

#ficha {
    margin-bottom: 180px;
}

.carousel_fich h3 {
    font-family: 'hallo_sansregular';
    font-size: 2rem;
    padding-left: 0.75rem;
}

.info-ficha {
    font-family: 'gilroylight';
    border-top: 1px solid #1565c0;
    border-bottom: 1px solid #1565c0;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 0 !important;
    color: #1565c0;
}

.info-ficha p {
    color: #1565c0;
    font-size: 1.3rem;
}

.info-ficha table {
    font-size: 0.9em;
}

.info-ficha table th {
    font-family: 'gilroyextrabold';
}

.btn-ficha {
    font-size: 0.8rem !important;
    font-family: 'gilroyextrabold';
    border: none;
    border-radius: 15px;
    display: inline-block;
    height: 32px;
    line-height: 32px;
    padding: 0 1rem;
}

.btn-ficha i {
    font-size: inherit;
    line-height: 32px;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .btn-ficha {
        margin-top: 2px;
        margin-bottom: 2px;
    }
}

#productos {
    padding-right: 20px;
    margin-bottom: 180px;
}

.info-adicional {
    font-family: 'gilroylight';
    color: #1565c0;
}

.info-adicional h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.info-adicional h4 {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: normal;
}

.info-adicional h5 {
    font-size: 1rem;
    margin-top: 4px;
    margin-bottom: 0;
}

.info-adicional p {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: normal;
}

.ficha-descargas {
    margin-bottom: 20px;
}

.ficha-descargas p {
    font-size: 1rem !important;
}

.img_detalle {
    border: 1px solid #dedede;
    /*  background-image: url(../img/sombra.png);
  background-position: center bottom;
  background-size: 100%;
  background-repeat: no-repeat;*/
}


/* -----------------------------------------------------------------------------------------------
COLORES
-------------------------------------------------------------------------------------------------- */

.colores {
    padding: 0 !important;
    height: 26px;
}

.colores li {
    display: inline-block;
    margin-left: 2px;
    margin-right: 2px;
    width: 12px;
    height: 26px;
    overflow: hidden;
    border-radius: 13px;
    position: relative;
}

.colores li a {
    display: block;
    text-indent: -9999px;
    cursor: pointer;
    line-height: 26px;
    position: relative;
}

.blanco {
    background-color: #FFF;
    border: 1px solid #ccc;
}

.blanco-microtexturado {
    background-color: #FFF;
    border: 1px solid #ccc;
}

.negro {
    background-color: #000;
}

.negro-microtexturado {
    background-color: #000;
}

.amarillo {
    background-color: #ffef3f;
}

.azul {
    background-color: #0a4197;
}

.beige {
    background-color: #fbdfcb;
}

.celeste {
    background-color: #8ac5eb;
}

.gris-microtexturado {
    background-color: #86929e;
}

.marron {
    background-color: #5b3512;
}

.marron-oxido {
    background-color: #622f03;
}

.oxidado {
    background-color: #622f03;
}

.marron-oxido-microtexturado {
    background-color: #622f03;
}

.plata {
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(163, 163, 168, 1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(163, 163, 168, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(163, 163, 168, 1) 100%);
    background: -o-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(163, 163, 168, 1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(163, 163, 168, 1) 100%);
    background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(163, 163, 168, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#a3a3a8', GradientType=1);
}

.aluminio {
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(163, 163, 168, 1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(163, 163, 168, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(163, 163, 168, 1) 100%);
    background: -o-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(163, 163, 168, 1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(163, 163, 168, 1) 100%);
    background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(163, 163, 168, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#a3a3a8', GradientType=1);
}

.rojo {
    background-color: #e50004;
}

.rosa {
    background-color: #eb82b0;
}

.verde {
    background-color: #00a22d;
}

.petroleo {
    background: rgba(0, 165, 210, 1);
}

.azul-metalizado {
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(34, 81, 162, 1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(34, 81, 162, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(34, 81, 162, 1) 100%);
    background: -o-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(34, 81, 162, 1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(34, 81, 162, 1) 100%);
    background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(34, 81, 162, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#2251a2', GradientType=1);
}

.petroleo-metalizado {
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(0, 165, 210, 1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(0, 165, 210, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(0, 165, 210, 1) 100%);
    background: -o-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(0, 165, 210, 1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(0, 165, 210, 1) 100%);
    background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(0, 165, 210, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00a5d2', GradientType=1);
}

.rojo-metalizado {
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(184, 0, 34, 1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(184, 0, 34, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(184, 0, 34, 1) 100%);
    background: -o-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(184, 0, 34, 1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(184, 0, 34, 1) 100%);
    background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(184, 0, 34, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#b80022', GradientType=1);
}

.verde-metalizado {
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(0, 163, 47, 1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(0, 163, 47, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(0, 163, 47, 1) 100%);
    background: -o-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(0, 163, 47, 1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(0, 163, 47, 1) 100%);
    background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(0, 163, 47, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00a32f', GradientType=1);
}

.violeta-metalizado {
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(135, 57, 145, 1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(135, 57, 145, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(135, 57, 145, 1) 100%);
    background: -o-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(135, 57, 145, 1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(135, 57, 145, 1) 100%);
    background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(135, 57, 145, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#873991', GradientType=1);
}

.acero {
    background: rgba(150, 151, 155, 1);
    background: -moz-linear-gradient(-45deg, rgba(150, 151, 155, 1) 0%, rgba(173, 174, 177, 1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(150, 151, 155, 1)), color-stop(100%, rgba(173, 174, 177, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(150, 151, 155, 1) 0%, rgba(173, 174, 177, 1) 100%);
    background: -o-linear-gradient(-45deg, rgba(150, 151, 155, 1) 0%, rgba(173, 174, 177, 1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(150, 151, 155, 1) 0%, rgba(173, 174, 177, 1) 100%);
    background: linear-gradient(135deg, rgba(150, 151, 155, 1) 0%, rgba(173, 174, 177, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#96979b', endColorstr='#adaeb1', GradientType=1);
}

.cobre {
    background: rgba(170, 83, 49, 1);
    background: -moz-linear-gradient(-45deg, rgba(170, 83, 49, 1) 0%, rgba(203, 98, 57, 1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(170, 83, 49, 1)), color-stop(100%, rgba(203, 98, 57, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(170, 83, 49, 1) 0%, rgba(203, 98, 57, 1) 100%);
    background: -o-linear-gradient(-45deg, rgba(170, 83, 49, 1) 0%, rgba(203, 98, 57, 1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(170, 83, 49, 1) 0%, rgba(203, 98, 57, 1) 100%);
    background: linear-gradient(135deg, rgba(170, 83, 49, 1) 0%, rgba(203, 98, 57, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#aa5331', endColorstr='#cb6239', GradientType=1);
}

.florentino {
    background: rgba(180, 133, 1, 1);
    background: -moz-linear-gradient(-45deg, rgba(180, 133, 1, 1) 0%, rgba(221, 163, 0, 1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(180, 133, 1, 1)), color-stop(100%, rgba(221, 163, 0, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(180, 133, 1, 1) 0%, rgba(221, 163, 0, 1) 100%);
    background: -o-linear-gradient(-45deg, rgba(180, 133, 1, 1) 0%, rgba(221, 163, 0, 1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(180, 133, 1, 1) 0%, rgba(221, 163, 0, 1) 100%);
    background: linear-gradient(135deg, rgba(180, 133, 1, 1) 0%, rgba(221, 163, 0, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#b48501', endColorstr='#dda300', GradientType=1);
}

.peltre {
    background: rgba(103, 135, 136, 1);
    background: -moz-linear-gradient(-45deg, rgba(103, 135, 136, 1) 0%, rgba(121, 159, 160, 1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(103, 135, 136, 1)), color-stop(100%, rgba(121, 159, 160, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(103, 135, 136, 1) 0%, rgba(121, 159, 160, 1) 100%);
    background: -o-linear-gradient(-45deg, rgba(103, 135, 136, 1) 0%, rgba(121, 159, 160, 1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(103, 135, 136, 1) 0%, rgba(121, 159, 160, 1) 100%);
    background: linear-gradient(135deg, rgba(103, 135, 136, 1) 0%, rgba(121, 159, 160, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#678788', endColorstr='#799fa0', GradientType=1);
}

.oro {
    background: rgba(181, 166, 15, 1);
    background: -moz-linear-gradient(-45deg, rgba(181, 166, 15, 1) 0%, rgba(236, 217, 23, 1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(181, 166, 15, 1)), color-stop(100%, rgba(236, 217, 23, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(181, 166, 15, 1) 0%, rgba(236, 217, 23, 1) 100%);
    background: -o-linear-gradient(-45deg, rgba(181, 166, 15, 1) 0%, rgba(236, 217, 23, 1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(181, 166, 15, 1) 0%, rgba(236, 217, 23, 1) 100%);
    background: linear-gradient(135deg, rgba(181, 166, 15, 1) 0%, rgba(236, 217, 23, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#b5a60f', endColorstr='#ecd917', GradientType=1);
}

.cromo {
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(163, 163, 168, 1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(163, 163, 168, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(163, 163, 168, 1) 100%);
    background: -o-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(163, 163, 168, 1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(163, 163, 168, 1) 100%);
    background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(163, 163, 168, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#a3a3a8', GradientType=1);
}

.mostaza {
    background-color: #f4ad57;
}

.azul-pastel {
    background-color: #1889a7;
}

.verde-pastel {
    background-color: #27a690;
}

.rosa-pastel {
    background-color: #fbbbc7;
}

.salmon {
    background-color: #fa7b85;
}

.gris-plomo {
    background-color: #3a3a3a;
}

.cobre-negro:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top-width: 13px;
    border-right-width: 0px;
    border-bottom-width: 13px;
    border-left-width: 12px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgba(170, 83, 49, 1);
    border-right-color: transparent;
    border-bottom-color: #000;
    border-left-color: transparent;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.cobre-blanco:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top-width: 13px;
    border-right-width: 0px;
    border-bottom-width: 13px;
    border-left-width: 12px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgba(170, 83, 49, 1);
    border-right-color: transparent;
    border-bottom-color: #fff;
    border-left-color: transparent;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.cobre-blanco {
    border: 1px solid #ccc;
}


/*------------------------------------------------------------------------------------------------------
 CAMPO BUSQUEDA
 -------------------------------------------------------------------------------------------------------*/


/* highlight results */

.ui-autocomplete span.hl_results {
    background-color: #ffff66;
}


/* scroll results */

.ui-autocomplete {
    max-height: 350px;
    overflow-y: auto;
    /* prevent horizontal scrollbar */
    overflow-x: hidden;
    /* add padding for vertical scrollbar */
    padding-right: 5px;
}

.ui-autocomplete::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 20px;
}

.ui-autocomplete::-webkit-scrollbar-thumb {
    background-color: #fff;
}

.result {
    padding-bottom: 12px;
}

.result h2 {
    font-size: 1.4em;
}

.sin-result {
    padding-top: 80px;
    padding-bottom: 100px;
}

.sin-result h2 {
    font-size: 1.4em;
}

.sin-result i {
    font-size: 5em;
}

.vent-buscador {
    min-height: 1px !important;
}

.btn_cert {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
}

.owl-nav i {
    vertical-align: middle;
}

.logos-sj {
    width: 100%;
    max-width: 743px;
}

#noticia {
    margin-bottom: 100px;
}

.ficha-not {
    margin-top: 60px;
}

.ficha-not h1 {
    cursor: pointer;
    display: block;
    font-family: 'hallo_sansregular';
    font-size: 2.8em !important;
    line-height: 30px;
    color: #E53935 !important;
    margin-bottom: 25px;
}

.ficha-not h2 {
    font-family: 'gilroylight';
    font-size: 1.6em !important;
    line-height: normal;
}

.ficha-not p {
    font-family: 'gilroylight';
    font-size: 1.2em !important;
}

.autor {
    margin-top: 25px;
}

.autor p {
    font-family: 'gilroylight';
    font-size: 1em !important;
    color: #E53935;
}

.autor h6 {
    font-family: 'gilroylight';
    font-size: 1em !important;
    color: #E53935;
}

.slider-ficha {
    margin-bottom: 60px;
}

.videoWrapper {
    position: relative;
    padding-bottom: 66.80%;
    padding-top: 0;
    height: 0;
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/* -----------------------------------------------------------------------------------------------
POP UP MAPA
-------------------------------------------------------------------------------------------------- */

#mapUp h1 {
    font-family: 'gilroylight';
    font-size: 2.2rem;
    color: #ff0002;
}

#mapUp p {
    font-family: 'gilroylight';
    font-size: 1.4rem;
}

#mapUp p strong {
    font-family: 'gilroyextrabold';
}


/* -----------------------------------------------------------------------------------------------
GLOBO MAPA
-------------------------------------------------------------------------------------------------- */

.datos-globo h3 {
    margin-bottom: 12px;
    margin-top: 0;
    font-family: 'gilroyextrabold';
    line-height: 23px;
    font-size: 1.6em;
    color: #E53935;
}

.datos-globo p {
    font-family: 'gilroylight';
    font-size: 1em;
    margin-bottom: 6px;
}

.datos-globo p strong {
    font-family: 'gilroyextrabold';
}

.datos-globo a {
    font-family: 'gilroylight';
    color: #1565C0;
    line-height: 22px;
}

.ubicacion-globo h5 {
    font-family: 'gilroylight';
    font-size: 1em;
    color: #E53935;
}

.datos-globo .list-inline {
    margin-top: 10px;
    border-top: 1px solid #1565C0;
    padding-top: 10px;
}

.datos-globo .list-inline li {
    display: inline-block !important;
    padding: 0 6px;
}

.datos-globo .list-inline li:first-child {
    padding-left: 0 !important;
}

@media all and (min-width: 800px) {
    /* Make it to fade-out while closing */
    .fancybox-custom-layout.fancybox-is-closing .fancybox-outer {
        opacity: 0;
    }
    /* Set color for background element */
    .fancybox-custom-layout .fancybox-bg {
        background: #f6f6f6;
    }
    .fancybox-custom-layout.fancybox-is-open .fancybox-bg {
        opacity: 1;
    }
    /* Move caption area to the right side */
    .fancybox-custom-layout .fancybox-caption {
        background: #fff;
        color: #333;
        bottom: 0;
        left: auto;
        padding: 30px 20px;
        right: 44px;
        top: 0;
        width: 256px;
    }
    /* Adjust content area position */
    .fancybox-custom-layout .fancybox-stage {
        right: 300px;
    }
    /* Remove top border from the caption */
    .fancybox-custom-layout .fancybox-caption {
        padding: 30px 20px;
        border: 0;
        color: #1565C0 !important;
    }
    /* Align buttons at the right side  */
    .fancybox-custom-layout .fancybox-toolbar {
        top: 0;
        right: 0;
        bottom: 0;
        left: auto;
        width: 44px;
        background: #333;
    }
    /* Remove background from all buttons */
    .fancybox-custom-layout .fancybox-button,
    .fancybox-custom-layout .fancybox-navigation button:before {
        background: transparent;
    }
    .fancybox-custom-layout .fancybox-button--arrow_right {
        right: 308px !important;
    }
    .fancybox-custom-layout .fancybox-navigation a {
        padding: 0;
    }
    .fancybox-custom-layout .fancybox-navigation a:not(.disabled) {
        color: #333;
    }
}

.bg-error {
    height: 100vh;
    background: rgba(16, 83, 167, 1);
    background: -moz-linear-gradient(top, rgba(16, 83, 167, 1) 0%, rgba(16, 83, 167, 1) 41%, rgba(45, 126, 192, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(16, 83, 167, 1)), color-stop(41%, rgba(16, 83, 167, 1)), color-stop(100%, rgba(45, 126, 192, 1)));
    background: -webkit-linear-gradient(top, rgba(16, 83, 167, 1) 0%, rgba(16, 83, 167, 1) 41%, rgba(45, 126, 192, 1) 100%);
    background: -o-linear-gradient(top, rgba(16, 83, 167, 1) 0%, rgba(16, 83, 167, 1) 41%, rgba(45, 126, 192, 1) 100%);
    background: -ms-linear-gradient(top, rgba(16, 83, 167, 1) 0%, rgba(16, 83, 167, 1) 41%, rgba(45, 126, 192, 1) 100%);
    background: linear-gradient(to bottom, rgba(16, 83, 167, 1) 0%, rgba(16, 83, 167, 1) 41%, rgba(45, 126, 192, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#1053a7', endColorstr='#2d7ec0', GradientType=0);
}

.pg-error {
    padding: 100px 0;
}

.pg-error h1 {
    font-family: 'gilroylight';
    font-size: 4rem;
    color: #FFF;
    margin-top: 100px;
}

.pg-error h1 strong {
    font-family: 'gilroyextrabold';
}

.pg-error p {
    font-family: 'gilroylight';
    font-size: 1.4rem;
    color: #FFF;
}


/* -----------------------------------------------------------------------------------------------
DESCARGAS
-------------------------------------------------------------------------------------------------- */

#descargas {
    padding-bottom: 80px;
}

#descargas .active .collapsible-body {
    background-color: transparent;
}

#descargas h6 {
    font-family: 'hallo_sansregular';
    font-size: 1.2rem;
    padding-left: 0.75rem;
}

.block-descarga {
    border: 1px solid #ccc;
    padding: 0.75rem !important;
}

#descargas .collapsible-header {
    font-family: 'gilroylight';
    font-size: 1.4rem;
    color: rgba(13, 71, 161, 0.7);
}

#descargas .collapsible-header small {
    font-size: 65% !important;
    margin-left: 20px;
}

#descargas .collapsible-header.active {
    color: white;
    background-color: #1565C0 !important;
}

#descargas .search-wrapper i.material-icons {
    position: absolute;
    top: 21px;
    right: 10px;
    cursor: pointer;
    color: #1565C0;
}

#descargas .search-wrapper input {
    color: #777;
    display: block;
}